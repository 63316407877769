import React, { useState, useEffect } from 'react';
import './LandingPage.css';
import Main from '../components/Main/Main';
import { Link, useNavigate } from 'react-router-dom';
import Modal from 'react-modal';

Modal.setAppElement('#root'); // Set the root element for accessibility

const LandingPage = () => {
    const [showNext, setShowNext] = useState(false);
    const navigate = useNavigate();
    const handleClick = () => {
        navigate('/login');
    };
    const [open, setOpen] = useState(false);
    const [ipAddress, setIpAddress] = useState('');

    useEffect(() => {
        fetch('https://api.ipify.org?format=json')
            .then(response => response.json())
            .then(data => {
                const fetchedIpAddress = data.ip;
                setIpAddress(fetchedIpAddress);
                const storedIpAddress = localStorage.getItem('ipAddress');
                if (storedIpAddress !== fetchedIpAddress) {
                    localStorage.setItem('ipAddress', fetchedIpAddress);
                    setOpen(true);
                }
            })
            .catch(error => console.error('Error fetching IP address:', error));
    }, []);

    return (
        <div className='landing-container'>
            <div>
                <div className="landing-inner-container" onClick={handleClick} id="start-loader">
                    <div className="logo">CYBER AI</div>
                    <a href="#" className="login-link">
                        <svg className="login-icon" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7 10l5 5 5-5z" fill="currentColor" />
                        </svg>
                    </a>
                    <br />
                </div>
                <span className="subtitle">your AI-powered security analysis partner</span>
                {/* React Modal */}
                <Modal
                    isOpen={open}
                    onRequestClose={() => setOpen(false)}
                    contentLabel="Video Modal"
                    className="react-modal-content"
                    overlayClassName="react-modal-overlay"
                >
                    <div className="modal-body bg-dark">
                        <div className="modal-container">
                            <div>
                                <h1 className="modal-header">Unlock the Potential of Cyber: Your Step-by-Step Guide</h1>
                            </div>
                            <div>
                                <div className="modal-close" onClick={() => setOpen(false)} aria-label="Close">x</div>
                            </div>
                        </div>
                        <div className='video-content'>
                            <video controls width="80%" height="auto">
                                <source src='https://s3.ap-south-2.amazonaws.com/cyber.deployh/Cyber+Intro+Video.mp4' type="video/mp4" />
                            </video>
                        </div>
                    </div>
                </Modal>
            </div>
        </div>
    );
};

export default LandingPage;
