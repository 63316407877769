import React, { useState } from 'react';

class Nodes {
  constructor(json) {
    this.json = json;
    this.position = { x: 0, y: 0 };
    this.nodes = [];
    this.edges = [];
    this.edgeType = "default";
    this.animated = false;
  }

  getValueStyle(value) {
    if (typeof value === "string") {
      return { color: "#fff", padding: 0, margin: 0 };
    }
    if (typeof value === "number") {
      return { color: "#46e254", padding: 0, margin: 0 };
    }
    if (typeof value === "boolean") {
      return { color: "yellow", padding: 0, margin: 0 };
    }
  }

  getNodes(input = this.json, parentID = "11", name = "Root", fileName) {
    let childNodeCounts = {};
    if (parentID === "11") {
      this.nodes.push({
        id: "11",
        data: {
          label: (
            <div style={{ padding: ".7rem 0" }}>
              <b
                style={{
                  background: "#122d42cb",
                  padding: ".7rem .5rem",
                  marginRight: "1rem",
                  color: "#fff",
                }}
              >{`./`}</b>
              <i style={{ padding: ".7rem 0" }}>{localStorage.getItem("filename")}</i>

            </div>
          ),
        },
        position: this.position,
        style: {
          background: "#122d424f",
          fontSize: ".95rem",
          textAlign: "left",
          color: "teal",
          border: "2px solid #122d42cb",
          minWidth: 220,
          padding: 0,
          textOverflow: "ellipsis !important",
          whiteSpace: "nowrap",
        },
      });
    }
    const countChildNodes = (input) => {
      let count = 0;

      if (typeof input === "object" && !Array.isArray(input)) {
        count = Object.keys(input).length;
      }

      if (Array.isArray(input)) {
        count = input.length;
      }

      return count;
    };

    const buildNodes = (input, parentID) => {
      let valueArray = [];
      Object.keys(input).map((key, index) => {
        if (typeof input[key] === "object" || Array.isArray(input[key])) {
          const id = `${parentID + 1}${index + 1} ${Math.random() * 1000000}`;
          childNodeCounts[id] = countChildNodes(input[key]);
          const getRandomSeverity = (count) => {
            if (count > 40) {
              return "high";
            } else if (count > 20) {
              return "medium";
            } else {
              return "low";
            }
          };
          this.nodes.push({
            id,
            data: {
              label: (
                <div style={{ padding: ".7rem 0", width: "auto" }}>
                  <NodeToolbar key={key} severity={getRandomSeverity(childNodeCounts[id])}>

                    <b
                      style={{
                        background: "#122d42cb",
                        padding: ".7rem .5rem",
                        marginRight: "1rem",
                        color: "#fff",
                      }}
                    >
                      {Array.isArray(input[key]) ? "" : ""} ({childNodeCounts[id]})
                    </b>

                    <i
                      style={{
                        background: "#122d424f",
                        fontSize: ".95rem",
                        textAlign: "left",
                        color: "#7286ff",
                        border: "2px solid #122d42cb",
                        padding: 0,
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {key}
                    </i>
                  </NodeToolbar>
                </div>
              ),
            },
            position: this.position,
            style: {
              background: "#122d424f",
              fontSize: ".95rem",
              textAlign: "left",
              color: "#9dcef3",
              border: "2px solid #122d42cb",
              minWidth: 280,
              padding: 0,
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            },
          });

          this.edges.push({
            id: id + parentID,
            source: parentID,
            target: id,
            type: this.edgeType,
            animated: this.animated,
          });

          buildNodes(input[key], id);
        } else {
          valueArray.push({ key: key, value: input[key] });
        }
      });

      if (valueArray.length > 0) {
        const id = `${Math.random() & 10000}${Math.random() * 10000}`;

        this.nodes.push({
          id,
          data: {
            label: (
              <div style={{ padding: "1rem" }}>
                {valueArray.map((value) => (
                  <p
                    style={{
                      width: "100%",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      display: "inline-block",
                      lineHeight: "1",
                      margin: 0,
                      marginTop: ".2rem",
                    }}
                  >
                    <b style={{ fontWeight: "bold" }}>{value.key}: </b>
                    <i
                      style={this.getValueStyle(value?.value)}
                    >{`${value?.value}`}</i>
                  </p>
                ))}
              </div>
            ),
          },
          position: this.position,
          style: {
            background: "#122d424f",
            fontSize: ".95rem",
            fontFamily: " 'Noto Sans Mono', monospace",
            textAlign: "left",
            color: "#ffd2a3",
            border: "2px solid #122d42cb",
            width: "auto",
            height: "auto",
            padding: 0,
          },
        });

        this.edges.push({
          id: id + parentID,
          source: parentID,
          target: id,
          type: this.edgeType,
          animated: this.animated,
        });
      }
    };


    buildNodes(input, parentID);

    this.nodes.forEach(node => {
      if (node.data && node.data.label) {
        const parentNodeID = node.id.substring(0, 2);
        if (childNodeCounts[parentNodeID]) {
          node.data.label = (
            <div style={{ padding: ".7rem 0", width: "auto" }}>
              <b
                style={{
                  background: "#122d42cb",
                  padding: ".7rem .5rem",
                  marginRight: "1rem",
                  color: "#fff",
                }}
              >
                {Array.isArray(input) ? "[]" : "{}"} ({childNodeCounts[parentNodeID]})
              </b>
              {node.data.label}
            </div>
          );
        }
      }
    });

    return [this.nodes, this.edges];
  }

  getNodeCount() {
    return this.nodes.length;
  }
}

const NodeToolbar = ({ children, severity }) => {
  const [showToolbar, setShowToolbar] = useState(false);

  const handleMouseEnter = () => {
    setShowToolbar(true);

  };

  const handleMouseLeave = () => {
    setShowToolbar(false);
  };



  const getToolbarStyle = () => {
    switch (severity) {

      case "low":
        return {
          background: "green",
          color: "white",
          padding: "0.4rem 0.7rem",
          borderRadius: "4px",
          zIndex: 999,
          top: "-4rem",
          left: "50%",
          width: "15rem",
          height: "3rem",
          transform: "translateX(-50%)",

        };
      default:
        return {};
    }
  };


  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={{ position: "relative", display: "inline-block" }}
    >
      {children}
      {showToolbar && (
        <div
          style={{
            position: "absolute",
            top: "-4rem",
            left: 50,
            ...getToolbarStyle(),
          }}

        >
          {severity === "high" && <h3>High severity</h3>}
          {severity === "medium" && <h3>Medium severity</h3>}
          {severity === "low" && <h3>Low severity</h3>}

        </div>
      )}
    </div>
  );
};
export default Nodes;
