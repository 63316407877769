const PersonIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={25}
    height={24}
    fill="none"
    data-original-title="Help"
    data-placement="top"
    data-toggle="tooltip"
    {...props}
  >
    <path
      stroke="#fff"
      d="M10.5 10a4.167 4.167 0 1 0 0-8.333 4.167 4.167 0 0 0 0 8.333ZM17.658 18.333c0-3.225-3.208-5.833-7.158-5.833s-7.158 2.608-7.158 5.833"
    />
  </svg>
);
export default PersonIcon;
