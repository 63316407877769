import React from "react";
import { MdMinimize } from "react-icons/md";
import { IoMdClose } from "react-icons/io";
const SPALayout = ({ children, setMinimize, minimize }) => {
  const onMinimize = () => {
    setMinimize(!minimize);
  };

  return (
    <>
      <div className="playground">
        <div className="handling-button-container">
          {/* <div className='handling-buttons'><MdMinimize size={20} onClick={onMinimize} /></div>
          <div className='handling-buttons'><IoMdClose size={20} onClick={onMinimize} /></div> */}
        </div>
        {children}
      </div>
    </>
  );
};

export default SPALayout;
