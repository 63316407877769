import React, { useState } from 'react'
import axios from "axios"
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Left from "../Left"
import CircleLoader from "../../helper/CircleLoader/CircleLoader"


const LinkedInRegister = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const [linkedInStatus, setLinkedInStatus] = useState(false)
    const [email, setEmail] = useState("")
    const code = urlParams.get('code');
    const navigate = useNavigate()


    axios.post(`${process.env.REACT_APP_SERVER_URL}api/linkedin-register/callback`, { code: code }).then((resp) => {
        if (resp.data.success === true) {
            setEmail(resp.data.email)
            setLinkedInStatus(true)
            toast.success("Linked Authorization Completed!")
        }
        else {
            toast.warn("Already Registered")
            navigate('/login')
        }

    }).catch((err) => {
        console.log(err)
    })

    const handleRegisterSubmit = (e) => {
        e.preventDefault();
        let company = e.target.company.value;
        const newData = {
            company: company
        }
        axios.patch(`${process.env.REACT_APP_SERVER_URL}api/update-data`, { email: email, newData: newData })
            .then(res => {
                toast.success("Registration Successful")
                navigate('/login')
            })
            .catch(err => {
                toast.warn("Enter Company Name!")
            })
    }

    return (
        <>
            {!linkedInStatus && <CircleLoader />}
            <div className="register-main">
                <Left />
                <div className="register-right">
                    <div className="register-right-container">
                        <div className="register-center">
                            <h2>Almost Finished</h2>
                            <p style={{ marginBottom: "1rem" }}>Enter your company name</p>
                            <form onSubmit={handleRegisterSubmit}>
                                <div>
                                    <input type="text" placeholder="Company or Business name" name="company" required={true} />
                                    <div className="center-buttons">
                                        <button className="button-1" style={{ width: "20%" }} type="submit">Next</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default LinkedInRegister