import React, { createContext, useState } from 'react';

const ComponentContext = createContext();

export const ComponentProvider = ({ children }) => {
    const [components, setComponents] = useState([]);


    const addComponent = (component) => {
        setComponents([...components, component]);
    };

    return (
        <ComponentContext.Provider value={{ components, addComponent }}>
            {children}
        </ComponentContext.Provider>
    );
};

export default ComponentContext;
