import React, { useState } from 'react';
import Sidebar from '../Sidebar/Sidebar';
import Home from '../Home/Home';
import './Main.css'
import { IoMenu } from "react-icons/io5";
import { ComponentProvider } from '../Context/ComponentContext'

function Main() {
  const [selectedFolderItem, setSelectedFolderItem] = useState(null);
  const [toggle, setToggle] = useState(true)

  const toggleProps = {
    toggle: toggle,
    setToggle: setToggle
  }

  const toggleSidebar = () => {
    setToggle(true)
  }

  const handleFolderItemClick = (item) => {
    setSelectedFolderItem(item);
  };
  return (
    <div className="ui-container">
      {!toggle && <IoMenu className="toggle-sidebar" onClick={toggleSidebar} size={30} />}
      {toggle && <Sidebar onFolderItemClick={handleFolderItemClick} toggleProps={toggleProps} />}
      <ComponentProvider>
        <Home selectedFolderItem={selectedFolderItem} />
      </ComponentProvider>
    </div>

  )
}

export default Main
