import "../../components/Sidebar/Sidebar.css";

const CHipIndi = (props) => {

  return (
    <svg
      className="chip-indicator-icon"
      fill="none"
      height={6}
      viewBox="0 0 12 6"
      width={12}
      xmlns="http://www.w3.org/2000/svg"
      style={{ transform: `rotate(${props.rotation}deg)` }}
      {...props}
    >
      <path
        clipRule="evenodd"
        d="M0.953755 0.674613C1.13347 0.46495 1.44912 0.44067 1.65878 0.620381L6.00005 4.34147L10.3413 0.620381C10.551 0.44067 10.8666 0.464951 11.0463 0.674614C11.2261 0.884277 11.2018 1.19993 10.9921 1.37964L6.32545 5.37964C6.1382 5.54013 5.8619 5.54013 5.67465 5.37964L1.00799 1.37964C0.798324 1.19993 0.774044 0.884276 0.953755 0.674613Z"
        fill="white"
        fillRule="evenodd"
      />
    </svg>
  );
};

export default CHipIndi;
