import React, { useState, useRef } from "react";
import { FaClipboardList, FaBug, FaTh } from "react-icons/fa";
import { FaFileAlt, FaShieldAlt } from "react-icons/fa";
import PreviewBoard from "../PreviewBoard/PreviewBoard";
import styles from "./View.module.scss";
import "./view.css"
const View = ({ layout, code, setCode, downloadCount }) => {
  const [activeTab, setActiveTab] = useState("preview-board");
  const diagramRef = useRef();

  return (
    <div className={styles.view}>
      <div className={styles.view__tabControls}>
        <div className={styles.view__nav}>

          <div className="Right_Side" >
            <div className="tooltip">
              <FaClipboardList className="icon" />
              <span className="tooltiptext" >Incident-List</span>
            </div>
            <div className="tooltip">
              <FaShieldAlt className="icon" />
              <span className="tooltiptext">Threat-Prioritization</span>
            </div>
            <div className="tooltip">
              <FaBug className="icon" />
              <span className="tooltiptext">Common-Vulnerability</span>
            </div>
            <div className="tooltip">
              <FaFileAlt className="icon" />
              <span className="tooltiptext">Report & incedent</span>
            </div>
            <div className="tooltip">
              <FaTh className="icon" />
              <span className="tooltiptext">HeatMap-Matrix</span>
            </div>
          </div>

        </div>
      </div>
      <PreviewBoard ref={diagramRef} setCode={setCode} code={code} layout={layout} />
    </div>
  );
};

export default View;
