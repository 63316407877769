import "../../components/Sidebar/Sidebar.css";

const ProjectEdit = (props) => (
  <svg
    className="project-edit-icon"
    fill="none"
    height={20}
    viewBox="0 0 20 20"
    width={20}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_179_422)">
      <path
        d="M9.16663 1.66669H7.49996C3.33329 1.66669 1.66663 3.33335 1.66663 7.50002V12.5C1.66663 16.6667 3.33329 18.3334 7.49996 18.3334H12.5C16.6666 18.3334 18.3333 16.6667 18.3333 12.5V10.8334"
        stroke="#666666"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
      />
      <path
        d="M13.3667 2.51669L6.80002 9.08335C6.55002 9.33335 6.30002 9.82502 6.25002 10.1834L5.89169 12.6917C5.75835 13.6 6.40002 14.2334 7.30835 14.1084L9.81669 13.75C10.1667 13.7 10.6584 13.45 10.9167 13.2L17.4834 6.63336C18.6167 5.50003 19.15 4.18336 17.4834 2.51669C15.8167 0.850027 14.5 1.38336 13.3667 2.51669Z"
        stroke="#666666"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.5}
      />
      <path
        d="M12.425 3.45825C12.9834 5.44992 14.5417 7.00825 16.5417 7.57492"
        stroke="#666666"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.5}
      />
    </g>
    <defs>
      <clipPath id="clip0_179_422">
        <rect fill="white" height={20} width={20} />
      </clipPath>
    </defs>
  </svg>
);
export default ProjectEdit;
