import React, { useEffect } from 'react'
import axios from "axios"
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import CircleLoader from "../../helper/CircleLoader/CircleLoader"


const LinkedInLogin = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    const navigate = useNavigate()


    axios.post(`${process.env.REACT_APP_SERVER_URL}api/linkedin/callback`, { code: code }).then((resp) => {
        console.log(resp)

        if (resp.data.success === true) {
            localStorage.setItem('auth', resp.data.token);
            navigate('/main', { replace: true })
            toast.success("Logged In Succeessfully")
        }
        else {
            toast.warn("Please Register!")
            navigate('/register')
        }

    }).catch((err) => {
        console.log(err)
    })

    return (
        <><CircleLoader /></>
    )
}

export default LinkedInLogin