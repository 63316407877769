import React from 'react';
import './SubscriptionCards.css';

const cardsData = [
  {
    subHeadings: 'starter',
    title: 'Free',
    points: ['Free boxmode domain', 'Ready to use template', 'Basic SEO settings', 'Support via email'],
    button: 'Start for free'
  },
  {
    subHeadings: 'Plus',
    title: '$6',
    points: ['Free custom domain for one year', 'Domain mapping', 'Remove ads', 'support via chat'],
    button: 'Buy Plan'
  },
  {
    subHeadings: 'premium',
    title: '$10 ',
    points: ['Project analytics tools', 'HTML iFrame', 'advance SEO and marketing tools', 'live tutorials sessions'],
    button: 'Buy Plan'
  }
];

const SubscriptionCards = () => {
  return (
    <div className="outer-box">
      <div className="card-container">
        {cardsData.map((card, index) => (
          <div className="card" key={index}>
            <h4 className='card-subheading'>{card.subHeadings}</h4>
            <h2 className="card-title">{card.title}</h2>
            <hr className="card-line" />
            <ul className="card-points">
              {card.points.map((point, i) => (
                <li key={i}>{point}</li>
              ))}
            </ul>
            <button className="card-button">{card.button}</button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SubscriptionCards;
