import React, { useContext, useEffect, useState } from 'react'
import SecurityReport from '../SPALayout/SecurityReport/SecurityReport'
import ComponentContext from '../Context/ComponentContext'
import SPALayout from '../SPALayout/SPALayout'
import { FiMaximize2 } from "react-icons/fi";
import { IoMdClose } from "react-icons/io";
import "./Home.css"


const Home = ({ selectedFolderItem }) => {

  const [minimize, setMinimize] = useState(true)

  // const { addComponent } = useContext(ComponentContext)

  const onMaximize = () => {
    setMinimize(!minimize)
  }
  return (
    <>
      <div className="right-container" style={{ position: "relative" }}>
        {selectedFolderItem && selectedFolderItem.type === 'security-report' ? (
          <>
            {minimize &&
              <>
                <SPALayout setMinimize={setMinimize} minimize={minimize}>
                  <SecurityReport />
                </SPALayout>
              </>
            }
            {!minimize && (
              <div className='minimized-toolbar'>
                <div className='minimized-window-header'>Window</div>
                <div className='handling-buttons'><FiMaximize2 size={20} onClick={onMaximize} /></div>
                <div className='handling-buttons'><IoMdClose size={20} /></div>
              </div>
            )}
          </>
        ) : (
          <div className="inner-container" id="inner-container" style={{ display: "flex" }}>
            <div className="main-container">
              <div id="start-container">
                <div className="text-container">
                  <img src="https://cyber.deployh.ai/assets/img/logo.png" style={{ width: "18rem", position: "absolute", marginRight: "15px", top: "5rem" }} />
                  <h3>Security, Simplified by AI</h3>
                  <p>Upload, Understand, Unlock, &amp; Uncover <br />By Security Reports, By Databreach Reports, By Logs and By ThreatHunting</p>
                </div>
              </div>

            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default Home
