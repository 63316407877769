import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from 'react-toastify';
import { useParams } from "react-router-dom";
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import Left from "../Left";

const ForgotPassword = () => {
    const [showPassword, setShowPassword] = useState(false);
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const { email } = useParams();
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (password !== confirmPassword) {
            toast.warn("Passwords not matched!");
        } else {
            const data = {
                email: email,
                password: password
            };
            axios.post(`${process.env.REACT_APP_SERVER_URL}api/change-password`, data)
                .then((resp) => {
                    console.log(resp);
                    if (resp.data.success === true) {
                        toast.success("Password Updated!");
                        navigate('/login');
                    } else {
                        toast.warn("Passwords Not Updated!");
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    };

    return (
        <div className="login-main">
            <Left />
            <div className="login-right">
                <div className="login-right-container">
                    <div className="login-center">
                        <h2>Reset Password</h2>
                        <p>Enter New Password</p>
                        <form onSubmit={handleSubmit}>
                            <div>
                                <div>
                                    <input
                                        style={{ width: "60%", marginRight: "0.5rem" }}
                                        type={showPassword ? "text" : "password"}
                                        placeholder="New password"
                                        name="password"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                    />
                                    <span className="eye-icon" onClick={() => setShowPassword(!showPassword)}>
                                        {showPassword ? (
                                            <FaEyeSlash style={{ cursor: 'pointer' }} />
                                        ) : (
                                            <FaEye style={{ cursor: 'pointer' }} />
                                        )}
                                    </span>
                                </div>
                                <div>
                                    <input
                                        style={{ width: "60%", marginRight: "0.5rem" }}
                                        type={showPassword ? "text" : "password"}
                                        placeholder="Confirm password"
                                        name="cpassword"
                                        value={confirmPassword}
                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                    />
                                    <span className="eye-icon" onClick={() => setShowPassword(!showPassword)}>
                                        {showPassword ? (
                                            <FaEyeSlash style={{ cursor: 'pointer' }} />
                                        ) : (
                                            <FaEye style={{ cursor: 'pointer' }} />
                                        )}
                                    </span>
                                </div >
                                <div className="center-buttons">
                                    <div className="button-inner">
                                        <button className="button-1" type="submit">Change Password</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <p id="bottom-p">
                            Already have an account? <Link to="/login">Login</Link>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ForgotPassword;
