const Reportbug = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={25}
    height={24}
    fill="none"
    data-original-title="Report Bug"
    data-placement="top"
    data-toggle="tooltip"
    {...props}
  >
    <path
      stroke="#fff"
      d="M17.5 18.43h-4l-4.45 2.96a.997.997 0 0 1-1.55-.83v-2.13c-3 0-5-2-5-5v-6c0-3 2-5 5-5h10c3 0 5 2 5 5v6c0 3-2 5-5 5Z"
    />
    <path
      stroke="#fff"
      d="M12.5 11.36v-.21c0-.68.42-1.04.84-1.33.41-.28.82-.64.82-1.3 0-.92-.74-1.66-1.66-1.66-.92 0-1.66.74-1.66 1.66M12.495 13.75h.01"
    />
  </svg>
);
export default Reportbug;
