import React, { useState } from "react";
import ProgressBar from "../../helper/ProgressBar/ProgressBar";
import "./SecurityReport.css";
import { MdMinimize } from "react-icons/md";
import { IoMdClose } from "react-icons/io";
import SearchBox from "../SearchBox/SearchBox";
import FileCard from "../SearchBox/FileCard/FileCard";
import Chat from "../Chat/Chat";
import Layout from "../../../Layout/Layout";

const SecurityReport = () => {
  const [showAnimation, setShowAnimation] = useState(false);
  const [close, setClose] = useState(false);
  const [progress, setProgress] = useState(20);
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileInfo, setFileInfo] = useState({ name: "", size: "" });
  const [showUpload, setShowUpload] = useState(true);
  const [srcCode, setSrcCode] = useState("");
  const [s3URL, setS3URL] = useState("");
  const [messages, setMessages] = useState([]);
  const [showPDF, setShowPDF] = useState(false);
  // console.log(s3URL);
  // console.log(messages);
  const removeFile = () => {
    setSelectedFile(null);
    setFileInfo({ name: "", size: "" });
    localStorage.removeItem("filename");
    setShowUpload(false);
  };

  return (
    <>
      <div className="container-text">
        {showUpload === true ? (
          <>{showAnimation && <ProgressBar progress={progress} />}</>
        ) : (
          <>{<Layout messages={messages} />}</>
        )}
      </div>
      {showUpload && (
        <SearchBox
          progress={progress}
          setShowAnimation={setShowAnimation}
          setProgress={setProgress}
          selectedFile={selectedFile}
          setSelectedFile={setSelectedFile}
          fileInfo={fileInfo}
          setFileInfo={setFileInfo}
          showUpload={showUpload}
          setShowUpload={setShowUpload}
          srcCode={srcCode}
          setSrcCode={setSrcCode}
          s3URL={s3URL}
          setS3URL={setS3URL}
          messages={messages}
          setMessages={setMessages}
        />
      )}
    </>
  );
};

export default SecurityReport;
