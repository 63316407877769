import "./ProgressBar.css"

const ProgressBar = ({ progress }) => {

    const messages = {
        "10": "Hang tight! Preparing your file...",
        "20": "Hang tight! Preparing your file...",
        "30": "Hang tight! Preparing your file...",
        "40": "Hang tight! Uploading your file...",
        "50": "File upload complete...",
        "60": "Processing your file...",
        "80": "Fetching details...",
        "100": "Processing Completed..."
    }

    return (
        <div className="progress-container">
            <div
                className="progress-bar"
                style={{ width: `${progress}%` }}
            ></div>
            <p className="progress-info">{`${progress}%`}</p>
            <p className="progress-info-meta">{`${messages[progress]}`}</p>
        </div>
    );
};

export default ProgressBar;
