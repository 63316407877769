const Refresh = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={25}
    height={24}
    fill="none"
    data-original-title="Refresh Page"
    data-placement="top"
    data-toggle="tooltip"
    {...props}
  >
    <path
      stroke="#fff"
      d="M9.4 7.56c.31-3.6 2.16-5.07 6.21-5.07h.13c4.47 0 6.26 1.79 6.26 6.26v6.52c0 4.47-1.79 6.26-6.26 6.26h-.13c-4.02 0-5.87-1.45-6.2-4.99M2.5 12h12.88"
    />
    <path stroke="#fff" d="M13.15 8.65 16.5 12l-3.35 3.35" />
  </svg>
);
export default Refresh;
