import React from 'react'
import "../Auth/Register/Register.css"

const Left = () => {
    return (
        <div className="register-left">
            <p className='tagline'>EFFICIENCY. CONTROL. VISIBILITY.</p>
            <h2 className='header'>Meet Cyber. Your AI Powered Security Analysis Partner</h2>
        </div >
    )
}

export default Left