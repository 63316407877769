import React, { useEffect, useState } from "react";
import LinkedINSvg from "../../../assets/icons/icons8-linkedin.svg";
import "./Login.css";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from 'react-toastify'
import Left from "../Left";

const Login = () => {
    // const [token, setToken] = useState(JSON.parse(localStorage.getItem("auth")) || "");
    const navigate = useNavigate();

    const handleLoginSubmit = async (e) => {
        e.preventDefault();
        let email = e.target.email.value;
        let password = e.target.password.value;

        if (email.length > 0 && password.length > 0) {
            const formData = {
                email,
                password,
            };
            try {
                const response = await axios.post(
                    `${process.env.REACT_APP_SERVER_URL}api/login`,
                    formData
                );
                localStorage.setItem('auth', response.data.token);
                toast.success("Login successfull");
                const usertype = response.data.usertype
                if (usertype === 'admin') {
                    navigate("/admin-home", { replace: true })
                }
                else {
                    navigate("/main", { replace: true })
                }
            } catch (err) {
                console.log(err);
                toast.error("Wrong Credentials!");
            }
        } else {
            setTimeout(() => {

                toast.error("Please fill all inputs");
            }, [2000])
        }
    };

    const handleLinkedInAuth = () => {
        axios.get(`${process.env.REACT_APP_SERVER_URL}api/linkedin-initiate`)
            .then((resp) => {
                const linkedInLink = resp.data.link;
                window.location.href = linkedInLink;
            }).catch((err) => {
                console.log(err)
            })
    }

    return (
        <div className="login-main">
            <Left />
            <div className="login-right">
                <div className="login-right-container">
                    <div className="login-center">
                        <h2>Welcome</h2>
                        <p>Please enter your details</p>
                        <form onSubmit={handleLoginSubmit}>
                            <div className='Auth-Forms'>
                                <input type="email" placeholder="email@business-name.com" name="email" />
                                <input
                                    type={"password"}
                                    placeholder="password"
                                    name="password"
                                />
                                <Link className="forgot-pass-link" to="/forgot-password">Forgot Password?</Link>
                                <div className="center-buttons">
                                    <button className="button-1" type="submit">Log In</button>
                                    <button className="button-2" type="submit" onClick={handleLinkedInAuth}>
                                        <img src={LinkedINSvg} alt="" />
                                        Log In with LinkedIN
                                    </button>
                                </div>
                            </div>
                        </form>
                        <p id="bottom-p">
                            Don't have an account? <Link to="/register">Sign Up</Link>
                        </p>
                    </div>
                </div>
            </div>
        </div >
    );
};

export default Login;
