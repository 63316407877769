import './App.css';
import LandingPage from './pages/LandingPage';
import Login from "./components/Auth/Login/Login";
import Register from "./components/Auth/Register/Register"
import ForgotPassword from "./components/Auth/ResetPassword/ForgotPassword"
import ResetPassword from "./components/Auth/ResetPassword/ResetPassword"
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Main from './components/Main/Main';
import { ToastContainer } from 'react-toastify';
import AdminHome from './components/Admin/AdminHome';
import SubscriptionCards from './components/Subscription/SubscriptionCards';
import LinkedInLogin from './components/Auth/LinkedIn/Login';
import LinkedInRegister from "./components/Auth/LinkedIn/Register"
import Layout from './Layout/Layout';
import { Navigate, Outlet } from 'react-router-dom'

const PrivateRoutes = () => {
    let token = localStorage.getItem('auth');
    return (
        token ? <Outlet /> : <Navigate to='/login' />
    )
}

function App() {


    return (
        <>
            <Router>
                <Routes>
                    <Route path="/" element={<LandingPage />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/linkedin-login/callback" element={<LinkedInLogin />} />
                    <Route path="/linkedin-register/callback" element={<LinkedInRegister />} />
                    <Route path="/register" element={<Register />} />
                    <Route path="/forgot-password" element={<ForgotPassword />} />
                    <Route path="/reset-password/:email" element={<ResetPassword />} />
                    <Route element={<PrivateRoutes />}>
                        <Route path="/main" element={<Main />} />
                        <Route path="/jsonGraph" element={<Layout />} />
                        <Route path="/admin-home" element={<AdminHome />} />
                        <Route path="/subscription-plans" element={<SubscriptionCards />} />
                    </Route>
                </Routes>
            </Router>
            <ToastContainer position='bottom-right' />
        </>
    );
}

export default App;