import React, { useState } from "react";
import styles from "./Layout.module.scss";
import Sidebar from "../components/JsonTOGraph/Sidebar/Sidebar";
import NavBar from "../components/JsonTOGraph/NavBar/NavBar";
import View from "../components/JsonTOGraph/View/View";

const Layout = ({ messages }) => {
  const [layout, setLayout] = useState("LR");
  const [downloadCount, setDownloadCount] = useState(0);
  const Json = JSON.parse(messages)
  const [code, setCode] = useState(Json);

  return (
    <div className={styles.layout}>
      <div className={styles.layout__main}>
        <div className={styles.layout__body}>
          <View
            downloadCount={downloadCount}
            code={code}
            setCode={setCode}
            layout={layout}
          />
        </div>
      </div>
    </div>
  );
};

export default Layout;
