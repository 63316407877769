import { useState, useEffect } from "react";
import CHipIndi from "../../assets/icons/ChipINDI";
import ProjectEdit from "../../assets/icons/ProjectEdit";
import "./Sidebar.css";
import FolderChip from "../../assets/icons/FolderChip";
import Refresh from "../../assets/icons/Refresh";
import Edit1 from "../../assets/icons/EditIcon";
import Reportbug from "../../assets/icons/ReportBug";
import RequestNew from "../../assets/icons/RequestNew";
import PersonIcon from "../../assets/icons/PersonIcon";
import { BsArrowBarLeft } from "react-icons/bs";
import { Link } from "react-router-dom";

const Sidebar = ({ onFolderItemClick, toggleProps }) => {
  const [isFolderItemsVisible, setFolderItemsVisible] = useState(false);
  const [isWorkspaceEditable, setWorkspaceEditable] = useState(false);
  const [rotation, setRotation] = useState(0);
  const [workspaceName, setWorkspaceName] = useState("New Project");
  const [isNewChipItemsVisible, setNewChipItemsVisible] = useState(false);

  // Sample chip items array
  const chipItems = [
    { id: 1, type: "security-report", name: "Security Analysis" },
    { id: 2, type: "data-breach-report", name: "Data Breach" },
    { id: 3, type: "log-analysis", name: "Log Analysis" },
    { id: 4, type: "threat-hunting", name: "Threat Hunting (Coming Soon)" },
  ];

  const toggleFolderItems = () => {
    setFolderItemsVisible(!isFolderItemsVisible);
  };

  const toggleWorkspaceEdit = () => {
    setWorkspaceEditable(!isWorkspaceEditable);
  };

  const handleWorkspaceNameChange = (e) => {
    setWorkspaceName(e.target.value);
    localStorage.setItem("workspaceName", e.target.value);
  };

  const toggleNewChipItems = () => {
    setNewChipItemsVisible(!isNewChipItemsVisible);
    setRotation(rotation === 0 ? -180 : 0);
  };

  const refreshPage = () => {
    window.location.reload();
  };

  const toggleSidebar = () => {
    toggleProps.setToggle(false);
  };

  return (
    <div className="sidebar-container" id="sidebar-container">
      <div className="deployh-logo">
        <div className="deployh-sidebar-title">
          <div className="deployh-first">CYBER AI - Security Savant</div>
          <BsArrowBarLeft
            size={25}
            onClick={toggleSidebar}
            id="close-sidebar"
          />
        </div>
        <div className="deployh-second">Powered by DEPLOYH.AI</div>
      </div>
      <div className="workspace-container">
        <div
          className="workspace-list-container"
          id="workspace-list-container"
        ></div>
        <div className="workspace-title">
          <span className="name">
            <CHipIndi onClick={toggleWorkspaceEdit} />
            <div className="name" onClick={toggleFolderItems}>
              Cyber Workspace
            </div>
            <div className="actions"></div> <Edit1 />
            <FolderChip onClick={toggleFolderItems} />
          </span>
        </div>
        {isFolderItemsVisible && (
          <div
            className={`folder-chip-container ${
              isFolderItemsVisible ? "show" : "hide"
            }`}
            id="folders-container-child-1"
          >
            <div className="folder-chip">
              <CHipIndi
                rotation={rotation}
                setFolderItemsVisible={setFolderItemsVisible}
              />
              <div
                className="name"
                id="name-project-456"
                onClick={toggleNewChipItems}
              >
                {isWorkspaceEditable ? (
                  <input
                    className="form-control forminput workspace-name-change"
                    autoFocus
                    onBlur={handleWorkspaceNameChange}
                  />
                ) : (
                  <span>{workspaceName}</span>
                )}
              </div>
              <div
                className=""
                hidden
                id="input-name-project-456"
                style={{ width: "inherit" }}
              >
                <input
                  className="form-control project-name-change"
                  data-id="456"
                  value={workspaceName}
                  onChange={handleWorkspaceNameChange}
                  id="project-456"
                  type="text"
                />
              </div>
              <div className="actions ">
                <ProjectEdit onClick={toggleWorkspaceEdit} />
              </div>
            </div>{" "}
            {isNewChipItemsVisible && (
              <div className="folder-chip-items">
                {chipItems.map((item) => (
                  <div
                    key={item.id}
                    className="folder-chip-item"
                    data-original-title="Upload report"
                    data-placement="left"
                    data-toggle="tooltip-new"
                    data-type={item.type}
                    onClick={() => onFolderItemClick(item)}
                  >
                    <div className="folder-chip-serial">{item.id}</div>
                    <div className="folder-chip-serial-text">{item.id}</div>
                    <div className="folder-chip-name">{item.name}</div>
                    {/* <div className="doc-three-dots" id="three-dot-security" style={{ display: 'none' }} />{ */}
                    {/* {
                      localStorage.getItem("securityAnalysisLoader") === 'true'
                        ? <div className="doc-three-dots" id="three-dot-security" />
                        : null
                    } */}
                  </div>
                ))}
              </div>
            )}
          </div>
        )}
      </div>
      <div
        className="workspace-list-container"
        id="workspace-list-container"
      ></div>
      <div className="side-bar-img">
        <img
          src="https://svgshare.com/i/13XW.svg"
          style={{ width: "70%", transform: "scale(1.4)" }}
        />
        <p className="text-secondary">The Cyber AI files are up to date.</p>
        <p className="text-secondary">Version issued: 6, 2024</p>
      </div>
      <div className="sidebar-footer">
        <div className="sidebar-footer-item">
          <a
            aria-label="Help"
            href={"https://deployhai.raiseaticket.com/#/createticket"}
            target="_blank"
          >
            <PersonIcon />
          </a>
        </div>
        <div className="sidebar-footer-item">
          <a
            aria-label="Request new feature"
            href="https://cyber-deployhai.canny.io/"
            target="_blank"
          >
            <RequestNew />
          </a>
        </div>
        <div className="sidebar-footer-item">
          <a
            aria-label="Report Bug"
            href="https://deployhai.raiseaticket.com/support/#/newticket"
            target="_blank"
          >
            <Reportbug />
          </a>
        </div>
        <div className="sidebar-footer-item">
          <a aria-label="Refresh Page" className="" onClick={refreshPage}>
            <Refresh />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
