import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';

import Left from "../Left";
import { BsFileEarmarkImageFill } from "react-icons/bs";

const ForgotPassword = () => {
    const [showCode, setShowCode] = useState(false)
    const [code, setCode] = useState()
    const [email, setEmail] = useState()
    const navigate = useNavigate();

    const submitReq = async (e) => {
        e.preventDefault();

        if (!showCode) {
            if (email.length > 0) {
                axios.post(`${process.env.REACT_APP_SERVER_URL}api/get-data`, { email: email })
                    .then((resp) => {
                        if (resp.data.success === true) {
                            axios.post(`${process.env.REACT_APP_SERVER_URL}api/forgot-password`, { email: email }).
                                then((resp) => {
                                    setShowCode(true)
                                })
                                .catch((err) => { console.log(err); })
                        }
                        else {
                            toast.warn("User Does not exist")
                        }
                    })
                    .catch((err) => console.log(err))
            }
            else {
                toast.error("Enter Mail")
            }
        }
        else {
            axios.post(`${process.env.REACT_APP_SERVER_URL}api/reset-password`, { email: email, otp: code }).then((resp) => {
                console.log(resp);
                if (resp.data.success) {
                    toast.success("OTP Verified!")
                    navigate(`/reset-password/${email}`)
                }
                else {
                    toast.warn("Incorrect OTP")
                }
            }).catch((err) => {
                console.log(err);
            })
        }
    };

    return (
        <div className="login-main">
            <Left />
            <div className="login-right">
                <div className="login-right-container">
                    <div className="login-center">
                        <h2>Forgot Password?</h2>
                        <p>Enter Registered Mail to receive code</p>
                        <form onSubmit={submitReq}>
                            <div>
                                <input type="email" placeholder="email@business-name.com" name="email" value={email}
                                    onChange={(e) => setEmail(e.target.value)} />
                                {showCode && <input type="text" placeholder="Enter 6 digit code" name="code" value={code}
                                    onChange={(e) => setCode(e.target.value)} />}
                            </div>
                            <div className="center-buttons" >
                                <div className="button-inner">
                                    {!showCode && <button style={{ padding: "0.5rem 3rem" }} className="button-1" type="submit">Next</button>}
                                    {showCode && <button style={{ padding: "0.5rem 3rem" }} className="button-1" type="submit">Request</button>}
                                </div>
                            </div>
                        </form>
                        <p id="bottom-p">
                            Already have an account? <Link to="/login">Login</Link>
                        </p>
                    </div>
                </div>
            </div>
        </div >
    );
};

export default ForgotPassword;
