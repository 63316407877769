import React from "react";
import "./FileCard.css";
import { FaFileAlt } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";

const FileCard = ({ removeFile, fileInfo, showUpload }) => {
  return (
    <div className="file-card-outer-container">
      <div className="file-card-container">
        <div className="file-card-icon">
          <FaFileAlt size={25} />
        </div>
        <div>
          <div className="file-card-info">
            <div className="file-name">{fileInfo.name}</div>
            <div className="file-size">{fileInfo.size}</div>
          </div>
        </div>
      </div>
      <div className="file-card-close">
        {!showUpload && (
          <span onClick={() => removeFile()}>
            {" "}
            <IoMdClose size={15} />
          </span>
        )}
      </div>
    </div>
  );
};

export default FileCard;
