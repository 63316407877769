import React, { useCallback, forwardRef, useEffect } from "react";
import ReactFlow, {
  addEdge,
  ConnectionLineType,
  useNodesState,
  useEdgesState,
  Controls,
  Background,
  ReactFlowProvider,
} from "react-flow-renderer";
import dagre from "dagre";

import Nodes from "./nodes";

import "./index.css";

const LayoutFlow = forwardRef(({ layout, code }, ref) => {
  const nodeInstance = new Nodes(code);

  const [newNodes, newEdges] = nodeInstance.getNodes();

  const dagreGraph = new dagre.graphlib.Graph();
  dagreGraph.setDefaultEdgeLabel(() => ({}));

  const nodeWidth = 272;
  const nodeHeight = 76;



  const getLayoutedElements = (nodes, edges, direction = "TB") => {
    const isHorizontal = direction === "LR";
    dagreGraph.setGraph({ rankdir: direction });

    nodes.forEach((node) => {
      dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight });
    });

    edges.forEach((edge) => {
      dagreGraph.setEdge(edge.source, edge.target);
    });

    dagre.layout(dagreGraph);

    // Inside getLayoutedElements function
    nodes.forEach((node, index) => {
      const nodeWithPosition = dagreGraph.node(node.id);
      node.targetPosition = isHorizontal ? "left" : "top";
      node.sourcePosition = isHorizontal ? "right" : "bottom";

      // We are shifting the dagre node position (anchor=center center) to the top left
      // so it matches the React Flow node anchor point (top left).
      node.position = {
        x: nodeWithPosition.x - nodeWidth / 2, // Adjust the position based on the index
        y: nodeWithPosition.y - nodeHeight / 2, // Adjust the position based on the index
      };
    });


    return { nodes, edges };
  };

  const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(
    newNodes,
    newEdges
  );

  const [nodes, setNodes, onNodesChange] = useNodesState(layoutedNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(layoutedEdges);

  const onConnect = useCallback(
    (params) =>
      setEdges((eds) =>
        addEdge(
          { ...params, type: ConnectionLineType.SmoothStep, animated: true },
          eds
        )
      ),
    []
  );
  const onLayout = useCallback(
    (direction) => {
      const { nodes: layoutedNodes, edges: layoutedEdges } =
        getLayoutedElements(nodes, edges, direction);

      // Update nodes state
      layoutedNodes.forEach((node) => {
        setNodes((prevNodes) =>
          prevNodes.map((prevNode) =>
            prevNode.id === node.id ? { ...prevNode, position: node.position } : prevNode
          )
        );
      });

      // Update edges state
      setEdges(layoutedEdges);
    },
    [nodes, edges, setNodes, setEdges]
  );


  useEffect(() => {
    if (layout) {
      onLayout(layout);
      console.log("called ", layout);
    }

  }, [layout]);

  return (
    <div
      ref={ref}
      style={{ width: "100%", height: "calc(100% - 30px)" }}
      className="layoutflow"
    >
      <div style={{ width: "100%", height: "100%" }}>
        <ReactFlowProvider>
          <ReactFlow
            nodes={nodes}
            edges={edges}
            // onNodesChange={onNodesChange}
            // onEdgesChange={onEdgesChange}
            onConnect={onConnect}
            connectionLineType={ConnectionLineType.SmoothStep}
            fitView={true}


          />
          <Controls />
          <Background
            id="2"
            gap={100}
            color="#ccc"
            style={{ backgroundColor: "#141414" }}

          />
        </ReactFlowProvider>
      </div>
    </div>
  );
});

export default LayoutFlow;
