import React, { useEffect, useState } from "react";
import LinkedINSvg from "../../../assets/icons/icons8-linkedin.svg";
import "./Register.css";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import Left from "../Left";

const Register = () => {
    const [showPassword, setShowPassword] = useState(false);
    const navigate = useNavigate();
    // const [token, setToken] = useState(JSON.parse(localStorage.getItem("auth")) || "");

    const handleRegisterSubmit = async (e) => {
        e.preventDefault();
        let name = e.target.name.value;
        let lastname = e.target.lastname.value;
        let email = e.target.email.value;
        let password = e.target.password.value;
        let confirmPassword = e.target.confirmPassword.value;
        let company = e.target.company.value;

        if (name.length > 0 && lastname.length > 0 && email.length > 0 && password.length > 0 && confirmPassword.length > 0) {

            if (password === confirmPassword) {
                const formData = {
                    firstname: name,
                    lastname: lastname,
                    email: email,
                    password: password,
                    confirmPassword: confirmPassword,
                    company: company
                };
                try {
                    const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}api/register`, formData);
                    toast.success("Registration successfull");
                    navigate("/login");
                } catch (err) {
                    toast.error(err.message);
                }
            } else {
                toast.error("Passwords don't match");
            }


        } else {
            toast.error("Please fill all inputs");
        }


    }

    const handleLinkedInAuth = () => {
        axios.get(`${process.env.REACT_APP_SERVER_URL}api/linkedin-register`)
            .then((resp) => {
                const linkedInLink = resp.data.link;
                window.location.href = linkedInLink;
            }).catch((err) => {
                console.log(err)
            })
    }

    return (
        <div className="register-main">
            <Left />
            <div className="register-right">
                <div className="register-right-container">
                    <div className="register-center">
                        <h2>Start Using Cyber Today</h2>
                        <p style={{ marginBottom: "1rem" }}>Enter your details</p>
                        <form onSubmit={handleRegisterSubmit}>
                            <div className='Auth-Forms'>
                                <div className="name-container">
                                    <input type="text" placeholder="First Name" name="name" style={{ marginRight: "0.5rem" }} required={true} />
                                    <input type="text" placeholder="Last Name" name="lastname" required={true} />
                                </div>
                                {/* example@company.com */}
                                <input type="email" placeholder="email@business-name.com" name="email" required={true} />
                                <input type="text" placeholder="Company or Business name" name="company" required={true} />
                                <input type="text" placeholder="Country" name="country" required={true} />
                                <input type={showPassword ? "text" : "password"} placeholder="Password" name="password" required={true} />
                                <input type={showPassword ? "text" : "password"} placeholder="Confirm Password" name="confirmPassword" required={true} />
                                <p id="terms-p">
                                    By registering you agree the <Link to='#'>Terms and Conditions</Link>
                                </p>
                                <div className="center-buttons">
                                    <button className="button-1" style={{ marginRight: "0.5rem" }} type="submit">Sign Up</button>
                                    <button className="button-2" type="submit" onClick={handleLinkedInAuth}>
                                        <img src={LinkedINSvg} alt="" />
                                        Sign Up with LinkedIN
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                    <p className="login-bottom-p">
                        Already have an account? <Link to="/login">Login</Link>
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Register;
