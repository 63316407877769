import React, { useRef, useState } from "react";
import axios from "axios";
import "./SearchBox.css";
import FileCard from "./FileCard/FileCard";
import Nodes from "../../JsonTOGraph/PreviewBoard/nodes";
const SearchBox = ({
  progress,
  setShowAnimation,
  setProgress,
  selectedFile,
  setSelectedFile,
  fileInfo,
  setFileInfo,
  setSrcCode,
  setS3URL,
  setShowUpload,
  messages,
  setMessages,
}) => {
  // const [messages, setMessages] = useState([]);
  const [uploading, setUploading] = useState(false);

  const [showFileDisplayBox, setShowFileDisplayBox] = useState(false);
  const [fileName, setFileName] = useState("");
  const fileInputRef = useRef(null);
  const [showFileInfo, setShowFileInfo] = useState(false);
  const [newMessage, setNewMessage] = useState("");

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${localStorage.getItem("auth")}`,
    }
  }


  const sendMessage = async () => {
    const formData = new FormData();
    formData.append("file", selectedFile);
    setProgress(20);
    setShowAnimation(true);
    await delay(2000);
    setProgress(30);
    // localStorage.setItem("securityAnalysisLoader", 'true');
    axios.post(`${process.env.REACT_APP_SERVER_URL}api/savefile`, formData, config)
      .then(async ({ data }) => {
        setSrcCode(data.code)
        setProgress(40)
        await delay(1000)
        setS3URL(data.url)

        setProgress(50)
        await delay(1000)
        const formDataChat = new FormData();
        formDataChat.append("sourceId", data.code);
        formDataChat.append(
          "prompt",

          `Generate a JSON structure that includes the total count of incidents and categorizes these incidents. For each category, provide a brief one-line description. The JSON should detail the following:
          Total number of incidents
          Categories of issues key will be Issue Categories
          A one-liner description for each issue category key will be Description.
          issue count of a category key will be count. 
          `
        );
        setProgress(60);
        await delay(1000);
        axios
          .post("https://cyber.deployh.ai/api/chat.php", formDataChat, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then(async ({ data }) => {
            setProgress(60);
            await delay(1000);
            const message = JSON.parse(JSON.stringify(data.content));
            setMessages([...messages, message]);
            setProgress(80);
            await delay(1000);

            setNewMessage("");
            setShowFileDisplayBox(false);
            setShowUpload(false);
            setProgress(100);

            setTimeout(() => {
              setShowAnimation(false);
              const nodes = new Nodes({});
              localStorage.setItem("filename", fileInfo.name)
              nodes.getNodes({}, "11", "Root", fileInfo.name);
            }, 2000);
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    if (file) {
      setUploading(true);
      setTimeout(() => {
        setFileInfo({ name: file.name, size: getFileSize(file.size) });
        setUploading(false);
        setShowFileInfo(true);
        setShowFileDisplayBox(true);

      }, 2000); // Set a timeout to wait for file information to be available
    }
  };

  const getFileSize = (size) => {
    if (size === 0) return "0 Bytes";
    const k = 1024;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    const i = Math.floor(Math.log(size) / Math.log(k));
    return parseFloat((size / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];
  };

  const removeFile = () => {
    setSelectedFile(null);
    setFileInfo({ name: "", size: "" });
    setShowFileDisplayBox(false);
  };
  return (
    <div className="searchbox">
      <fieldset>
        <legend>Search</legend>
        <div className="buttons-container">
          <div className="upload__file-info">
            <label
              htmlFor="fileInput"
              id="attach-button"
              className="attach-button"
              style={{ visibility: "visible" }}
            >
              <span
                className="loader"
                id="loader"
                style={{ display: uploading ? "block" : "none" }}
              ></span>
              <svg
                id="attach"
                style={{ display: !uploading ? "block" : "none" }}
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                fill="currentColor"
                viewBox="0 0 256 256"
              >
                <path d="M208.25,123.76a6,6,0,0,1,0,8.49l-82.06,82a54,54,0,0,1-76.36-76.39L149.1,37.14a38,38,0,1,1,53.77,53.72L103.59,191.54a22,22,0,1,1-31.15-31.09l83.28-84.67a6,6,0,0,1,8.56,8.42L81,168.91a10,10,0,1,0,14.11,14.18L194.35,82.4a26,26,0,1,0-36.74-36.8L58.33,146.28a42,42,0,1,0,59.37,59.44l82.06-82A6,6,0,0,1,208.25,123.76Z"></path>
              </svg>
            </label>
            {showFileDisplayBox && (
              <FileCard removeFile={removeFile} fileInfo={fileInfo} />
            )}
          </div>
          <input
            type="file"
            id="fileInput"
            style={{ display: "none" }}
            accept=".pdf"
            onChange={handleFileChange}
          />
          <input
            type="text"
            name=""
            id="chat-message"
            style={{ display: "none" }}
            className="form-control input"
            placeholder="Type Something......"
          />
          <button
            type="button"
            onClick={sendMessage}
            className="search-button"
            id="submit-Doc"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="1em"
              height="1em"
              fill="currentColor"
              viewBox="0 0 256 256"
              className="h-4 w-auto"
            >
              <path d="M232,127.89a16,16,0,0,1-8.18,14L55.91,237.9A16.14,16.14,0,0,1,48,240a16,16,0,0,1-15.05-21.34L60.3,138.71A4,4,0,0,1,64.09,136H136a8,8,0,0,0,8-8.53,8.19,8.19,0,0,0-8.26-7.47H64.16a4,4,0,0,1-3.79-2.7l-27.44-80A16,16,0,0,1,55.85,18.07l168,95.89A16,16,0,0,1,232,127.89Z"></path>
            </svg>
          </button>
          <button
            type="button"
            className="search-button"
            style={{ display: "none" }}
            id="submit-Msg"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="1em"
              height="1em"
              fill="currentColor"
              viewBox="0 0 256 256"
              className="h-4 w-auto"
            >
              <path d="M232,127.89a16,16,0,0,1-8.18,14L55.91,237.9A16.14,16.14,0,0,1,48,240a16,16,0,0,1-15.05-21.34L60.3,138.71A4,4,0,0,1,64.09,136H136a8,8,0,0,0,8-8.53,8.19,8.19,0,0,0-8.26-7.47H64.16a4,4,0,0,1-3.79-2.7l-27.44-80A16,16,0,0,1,55.85,18.07l168,95.89A16,16,0,0,1,232,127.89Z"></path>
            </svg>
          </button>
        </div>
        {/* Textarea element */}
      </fieldset>
    </div>
  );
};

export default SearchBox;
